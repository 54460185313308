import { Layout } from "antd";
import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch, } from "react-router-dom";
import WatchB from '../../assets/menu_icon/会议查看-紫.png';
import WatchC from '../../assets/menu_icon/日历-紫.png';
import RecordB from '../../assets/menu_icon/会议记录-紫.png';
import BookB from '../../assets/menu_icon/会议预约-紫.png';
import { Button, Loading } from '../../common/extendedAntdWidget/export';
import { ApiUtil } from '../../utils/ApiUtils';
import Framework from "./Framework";
import Header from './Header';
import Menu, { menuEmitter } from "./Menu";
import { Booking, Conference, Control, Personal, Room, Users, Dashboard, Panel, Meeting, MeetingMap, Workbench, SharedBench, MeetingList, Vote } from './pages';
import RoomB from '../../assets/menu_icon/会议室看板-紫.png'
import CountIcon from '../../assets/zh_icon/count.png'
import ContactB from '../../assets/menu_icon/contact-p.png'
import mapImg from '../../assets/zh_icon/map_1.png'

const { Content } = Layout;
class PortalContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.downUrl = '';
    }

    searchAction = async (downUrl) => {
        this.downUrl = downUrl;
    }

    downExcel = async () => {
        const data = await ApiUtil.download(this.downUrl,{});
    }

    render() {

        let allMenus = this.props.portal.myData.menus;
        let portalMenus = []
        allMenus.map(menu => {
            if (menu.serviceType == 1 && menu.isShow == 1) {
                portalMenus.push(menu)
            }
        })
        portalMenus.sort((a, b) => a.priority < b.priority)


        return (
                <Layout
                    style={{ height: '100%', overflow: 'hidden', display: 'flex' }}
                    id={'my-root'}
                >
                    <Header hide={window.HIDE_HEADER}/>
                    <Content style={{ backgroundColor: '#F1F1F2', flex: 1, overflowY: 'hidden', overflowX: 'hidden', display : 'flex'}}>
                        <Menu/>
                        <div
                            id={"portal-content"}
                            style={{flex : 1,  
                                // boxShadow : '0 0 6px 0 rgb(0 0 0 / 14%)', 
                                background : 'transparent', margin :20, width : '0', display : 'flex'}}
                        >
                            <Switch>
                                
                                {/* TODO 权限判断没加 估计是只有管理员能进dashboard */}
                                <Redirect
                                    from={"/"}
                                    // to={portalMenus[0].dataUrl}
                                    to='dashboard'
                                    exact
                                />
                                {/* <Route
                                    path={"/dashboard"}
                                    component={() => {
                                        return <Suspense fallback={<Loading/>}><div style={{flex : 1, overflowY : 'overlay'}}><Dashboard confType={[
                                            // {value: "RTC会议", key: 0},
                                            {value: "腾讯会议", key: 1},
                                            {value: "本地会议", key: 2},
                                            // {value: "混合会议", key: 3},
                                        ]} me={this.props.portal.myData}/></div></Suspense>
                                    }}
                                /> */}
                                <Route
                                    path={"/panel"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'panel')
                                        return <Suspense fallback={<Loading/>}><Framework hideTitle={true} title={'会议工作台'} icon={BookB} content={<Panel me={this.props.portal.myData}/>}/></Suspense>
                                    }
                                }
                                />
                                <Route
                                    path={"/booking"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'booking')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议预约'} icon={BookB} content={<Booking me={this.props.portal.myData}/>}/></Suspense>
                                    }
                                }
                                />
                                <Route
                                    path={"/update"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'booking')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议修改'} icon={BookB} content={<Booking me={this.props.portal.myData}/>}/></Suspense>
                                    }
                                }
                                />
                                <Route
                                    path={"/conferences"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'conferences')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议查看'} icon={WatchB} content={<Conference me={this.props.portal.myData} searchText={this.searchAction}/>} addition={
                                            <div>
                                                <Button type={'primary'} style={{ marginRight: 15 }} onClick={() => {
                                                    this.downExcel()
                                                }}>全部导出</Button>
                                                 <Button type={'primary'} onClick={() => {
                                                        window.location.href = window.location.origin + window.MY_PREFIX + '/#/booking'
                                                }}>会议预约</Button>
                                        </div>
                                        }/></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/meeting"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'conferences')
                                        return <Suspense fallback={<Loading/>}><Framework hideTitle={false} title={'会议查看'} icon={WatchB} content={<MeetingList me={this.props.portal.myData} />} /></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/control"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'conferences')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议控制'} content={<Control me={this.props.portal.myData}/>}/></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/map"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'map')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议室地图'} icon={mapImg} content={<MeetingMap me={this.props.portal.myData}/>}/></Suspense>
                                    }}
                                />
                                {/* <Route
                                    path={"/history"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'history')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议记录'} icon={RecordB} content={<Conference me={this.props.portal.myData} searchText={this.searchAction} type={'history'} />} addition={
                                            <div>
                                                <Button type={'primary'} onClick={() => {
                                                    this.downExcel()
                                                }}>全部导出</Button>
                                        </div>
                                        }/></Suspense>
                                    }}
                                /> */}
                                <Route
                                    path={"/record"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'history')
                                        return <Suspense fallback={<Loading/>}><Framework hideTitle={false} title={'会议记录'} icon={WatchB} content={<Meeting me={this.props.portal.myData} type={'history'} />} /></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/workbench"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'workbench')
                                        return <Suspense fallback={<Loading/>}><Framework hideTitle={false} title={'会议统计'} icon={CountIcon} content={<Workbench me={this.props.portal.myData} />} /></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/dashboard"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'dashboard')
                                        return <Suspense fallback={<Loading/>}><Framework hideTitle={false} title={'会议工作台'} icon={WatchC} content={<SharedBench me={this.props.portal.myData} />} /></Suspense>
                                    }}
                                />
                                <Route
                                    path={"/personal"}
                                    component={() => {
                                        return <Suspense fallback={<Loading/>}><div style={{flex : 1, overflowY : 'hidden', display : 'flex', justifyContent : 'center'}}><Personal /></div></Suspense>
                                    }}
                                />

                                <Route
                                    path={"/room"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'room-schedule')
                                        return <Suspense fallback={<Loading/>}><Framework title={'会议室看板'} icon={RoomB} content={<Room me={this.props.portal.myData}/>}/></Suspense>
                                    }}
                                />
                                
                                <Route
                                    path={"/vote"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'vote')
                                        return <Suspense fallback={<Loading/>}><Framework title={'投票'} icon={ContactB} content={<Vote me={this.props.portal.myData}/>}/></Suspense>
                                    }}
                                />
                                
                                <Route
                                    path={"/users"}
                                    component={() => {
                                        menuEmitter.emit('setSeleted', 'users')
                                        return <Suspense fallback={<Loading/>}><Framework title={'常用联系人'} icon={ContactB} content={<Users me={this.props.portal.myData}/>}/></Suspense>
                                    }}
                                />
                            </Switch>
                        </div>

                    </Content>

                </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        portal : state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer : (target) => {
            dispatch ({
                type : "setPortalReducer",
                payload : target
            });
        },

   
    }
};

export default connect(mapStateToProps, mapDispachToProps)(PortalContent);